/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    userList: {
        data: [],
    },
    error: "",
};
const userListSlice = createSlice({
    name: "Users",
    initialState,
    reducers: {
        userListPending: (state) => {
            state.isLoading = true;
            state = initialState;
        },
        userListSuccess: (state, action) => {
            state.isLoading = false;
            state.userList = action.payload;
            state.error = "";
        },
        userListFail: (state: any, payload: any) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});
export const { userListPending, userListSuccess, userListFail } =
    userListSlice.actions;
export default userListSlice.reducer;
