/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    dashboard: [{
        rowOne: [],
        rowTwo: [{
            piaChart: {},
            areaChart: []
        }],
        rowThird: [{
            tableData: [],
            countData: []
        }]
    }],

    error: "",
};
const dashboardSlice = createSlice({
    name: "Dashboard",
    initialState,
    reducers: {
        dashboardPending: (state) => {
            state.isLoading = true;
            state = initialState;
        },
        dashboardSuccess: (state, action) => {
            state.isLoading = false;
            state.dashboard = action.payload;
            state.error = "";
        },
        dashboardFail: (state: any, payload: any) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});
export const { dashboardPending, dashboardSuccess, dashboardFail } =
    dashboardSlice.actions;
export default dashboardSlice.reducer;
