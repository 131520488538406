import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from "./components/preloader";

//feelyou
const FeelyouSignIn = lazy(() => import("./pages/feelyou/signin"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const DashboardOne = lazy(() => import("./pages/feelyou/dashboard-one"));
const Tracks = lazy(() => import("./pages/feelyou/tracks"));
const UserList = lazy(() => import("./pages/feelyou/userList"));

const App = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route path="/" element={<FeelyouSignIn />} />
                        <Route path="/signin" element={<FeelyouSignIn />} />
                        {/* Auth Page Route */}
                        <Route path="/dashboard" element={<DashboardOne />} />
                        <Route path="/tracks" element={<Tracks />} />
                        <Route path="/users" element={<UserList />} />
                        {/* 404 Page Route */}
                        <Route path="*" element={<ErrorNotFound />} />
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
