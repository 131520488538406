/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    moodLoading: false,
    genreLoading:false,
    trackList: {
        data: [],
    },
    moods: {
        data: [],
    },
    genres:{
        data:[]
    },
    error: "",
};
const trackListSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        trackListPending: (state) => {
            state.isLoading = true;
            state = initialState;
        },
        trackListSuccess: (state, action) => {
            state.isLoading = false;
            state.trackList = action.payload;
            state.error = "";
        },
        trackListFail: (state: any, payload: any) => {
            state.isLoading = false;
            state.error = payload;
        },
        moodListPending: (state) => {
            state.moodLoading = true;
        },
        moodListSuccess: (state, action) => {
            state.moodLoading = false;
            state.moods = action.payload;
            state.error = "";
        },
        moodListFail: (state: any, payload: any) => {
            state.moodLoading = false;
            state.error = payload;
        },
        genreListPending: (state) => {
            state.genreLoading = true;
        },
        genreListSuccess: (state, action) => {
            state.genreLoading = false;
            state.genres = action.payload;
            state.error = "";
        },
        genreListFail: (state: any, payload: any) => {
            state.genreLoading = false;
            state.error = payload;
        },
    },
});
export const {
    trackListPending,
    trackListSuccess,
    trackListFail,
    moodListPending,
    moodListSuccess,
    moodListFail,
    genreListPending,
    genreListSuccess,
    genreListFail
} = trackListSlice.actions;
export default trackListSlice.reducer;
