/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    userData: {
        id: "",
        name: "",
        email: "",
        role: "",
        ip: "",
        created: "",
        updated: "",
        isVerified: "",
        jwtToken: "",
    },
    error: "",
};
const authSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.isLoading = false;
            state.userData = action.payload;
            state.error = "";
        },
        loginFail: (state: any, payload: any) => {
            state.isLoading = false;
            state.isLoading = false;
            state.error = payload;
        },
    },
});
export const { loginPending, loginSuccess, loginFail } = authSlice.actions;
export default authSlice.reducer;
