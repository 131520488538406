import { PieChart, Music, User } from "react-feather";

const fymMenus = [
    {
        id: 1,
        label: "Dashboard",
        url: "/dashboard",
        Icon: PieChart,
    },
    {
        id: 2,
        label: "Tracks",
        url: "/tracks",
        Icon: Music,
    },
    {
        id: 3,
        label: "Users",
        url: "/users",
        Icon: User,
    },
];

export default fymMenus;
